import React from 'react';
import { signInWithGoogle } from '../supabaseClient';

const Auth = () => {
    return (
        <div className="flex justify-center items-center h-screen bg-darkBlue">
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 max-w-sm w-full">
                <div className="text-center mb-6">
                    <h1 className="text-2xl font-bold text-darkBlue">Welcome to PressHive Admin</h1>
                    <p className="text-lightBlue">Sign in to continue</p>
                </div>
                <div className="flex items-center justify-center">
                    <button
                        className="bg-red hover:bg-darkBlue text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                        type="button"
                        onClick={signInWithGoogle}
                    >
                        Sign In with Google
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Auth;

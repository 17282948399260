import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

const AddClientForm = ({ onClientAdded, onClientUpdated, editingClient, onCancel }) => {
    const [name, setName] = useState('');
    const [workerName, setWorkerName] = useState('');
    const [workers, setWorkers] = useState([]);
    const [routes, setRoutes] = useState([{ pattern: '', zone_id: '' }]);
    const [domains, setDomains] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState('');

    useEffect(() => {
        fetchWorkers();
        fetchDomains();
        if (editingClient) {
            setName(editingClient.name);
            setSelectedDomain(editingClient.domain || '');
            fetchClientDetails(editingClient.id);
        }
    }, [editingClient]);

    async function fetchWorkers() {
        const { data, error } = await supabase
            .from('worker_configurations')
            .select('id, name');
        if (error) console.error('Error fetching workers:', error);
        else setWorkers(data);
    }

    async function fetchDomains() {
        try {
            const { data, error } = await supabase.functions.invoke('fetch-cloudflare-domains', {
                body: { page: 1 },
            });
            if (error) throw error;
            setDomains(data.result);
        } catch (error) {
            console.error('Error fetching domains:', error);
        }
    }

    async function fetchClientDetails(clientId) {
        const { data, error } = await supabase
            .from('clients')
            .select(`
                *,
                worker_configurations (name),
                client_routes (routes)
            `)
            .eq('id', clientId)
            .single();

        if (error) {
            console.error('Error fetching client details:', error);
        } else if (data) {
            setWorkerName(data.worker_configurations?.name || '');
            setRoutes(data.client_routes?.routes || [{ pattern: '', zone_id: '' }]);
            setSelectedDomain(data.domain || '');
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let worker_id;

        // Check if we need to create a new worker
        if (!workers.some(w => w.name === workerName)) {
            const { data: newWorker, error: workerError } = await supabase
                .from('worker_configurations')
                .insert({ name: workerName, config: {} })
                .select()
                .single();

            if (workerError) {
                console.error('Error creating new worker:', workerError);
                return;
            }
            worker_id = newWorker.id;
        } else {
            worker_id = workers.find(w => w.name === workerName).id;
        }

        if (editingClient) {
            // Update existing client
            const { data: updatedRoutes, error: routesError } = await supabase
                .from('client_routes')
                .update({ routes })
                .eq('id', editingClient.routes_id)
                .select()
                .single();

            if (routesError) {
                console.error('Error updating client routes:', routesError);
                return;
            }

            const { data: updatedClient, error: clientError } = await supabase
                .from('clients')
                .update({ name, worker_id, domain: selectedDomain })
                .eq('id', editingClient.id)
                .select()
                .single();

            if (clientError) {
                console.error('Error updating client:', clientError);
            } else {
                onClientUpdated({ ...updatedClient, routes: updatedRoutes.routes });
            }
        } else {
            // Insert new client
            const { data: clientRoutes, error: routesError } = await supabase
                .from('client_routes')
                .insert({ routes })
                .select()
                .single();

            if (routesError) {
                console.error('Error adding client routes:', routesError);
                return;
            }

            const { data: client, error: clientError } = await supabase
                .from('clients')
                .insert({ name, worker_id, routes_id: clientRoutes.id, domain: selectedDomain })
                .select()
                .single();

            if (clientError) {
                console.error('Error adding client:', clientError);
            } else {
                onClientAdded({ ...client, routes: clientRoutes.routes });
            }
        }

        setName('');
        setWorkerName('');
        setSelectedDomain('');
        setRoutes([{ pattern: '', zone_id: '' }]);
    };

    const handleRouteChange = (index, field, value) => {
        const newRoutes = [...routes];
        newRoutes[index][field] = value;
        setRoutes(newRoutes);
    };

    const addRoute = () => {
        const selectedZone = domains.find(d => d.name === selectedDomain);
        const newRoute = { 
            pattern: '', 
            zone_id: selectedZone ? selectedZone.id : ''
        };
        setRoutes([...routes, newRoute]);
    };

    const removeRoute = (index) => {
        const newRoutes = routes.filter((_, i) => i !== index);
        setRoutes(newRoutes);
    };

    const handleDomainChange = (domain) => {
        setSelectedDomain(domain);
        const selectedZone = domains.find(d => d.name === domain);
        if (selectedZone) {
            const newRoutes = routes.map(route => ({ ...route, zone_id: selectedZone.id }));
            setRoutes(newRoutes);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4 bg-lightGray p-6 rounded-md shadow-md">
            <div>
                <label htmlFor="name" className="block text-sm font-medium text-darkBlue">Client Name</label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 block w-full border border-lightBlue rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-darkBlue focus:border-darkBlue sm:text-sm"
                    required
                />
            </div>
            <div>
                <label htmlFor="domain" className="block text-sm font-medium text-darkBlue">Cloudflare Domain</label>
                <input
                    type="text"
                    id="domain"
                    value={selectedDomain}
                    onChange={(e) => handleDomainChange(e.target.value)}
                    list="domains"
                    className="mt-1 block w-full border border-lightBlue rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-darkBlue focus:border-darkBlue sm:text-sm"
                    required
                />
                <datalist id="domains">
                    {domains.map(domain => (
                        <option key={domain.id} value={domain.name} />
                    ))}
                </datalist>
            </div>
            <div>
                <label htmlFor="worker" className="block text-sm font-medium text-darkBlue">Worker</label>
                <input
                    type="text"
                    id="worker"
                    value={workerName}
                    onChange={(e) => setWorkerName(e.target.value)}
                    list="workers"
                    className="mt-1 block w-full border border-lightBlue rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-darkBlue focus:border-darkBlue sm:text-sm"
                    required
                />
                <datalist id="workers">
                    {workers.map(worker => (
                        <option key={worker.id} value={worker.name} />
                    ))}
                </datalist>
            </div>
            <div>
                <label className="block text-sm font-medium text-darkBlue">Routes</label>
                {routes.map((route, index) => (
                    <div key={index} className="flex space-x-2 mt-2">
                        <input
                            type="text"
                            placeholder="Pattern"
                            value={route.pattern}
                            onChange={(e) => handleRouteChange(index, 'pattern', e.target.value)}
                            className="flex-1 border border-lightBlue rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-darkBlue focus:border-darkBlue sm:text-sm"
                            required
                        />
                        <input
                            type="text"
                            placeholder="Zone ID"
                            value={route.zone_id}
                            onChange={(e) => handleRouteChange(index, 'zone_id', e.target.value)}
                            className="flex-1 border border-lightBlue rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-darkBlue focus:border-darkBlue sm:text-sm"
                            required
                            readOnly
                        />
                        <button
                            type="button"
                            onClick={() => removeRoute(index)}
                            className="bg-red hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                        >
                            Remove
                        </button>
                    </div>
                ))}
                <button
                    type="button"
                    onClick={addRoute}
                    className="mt-2 bg-darkBlue hover:bg-lightBlue text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                    Add Route
                </button>
            </div>
            <div className="flex justify-end space-x-2">
                <button
                    type="button"
                    onClick={onCancel}
                    className="bg-gray-300 hover:bg-gray-400 text-darkBlue font-bold py-2 px-4 rounded transition duration-300"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="bg-darkBlue hover:bg-lightBlue text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                    {editingClient ? 'Update Client' : 'Add Client'}
                </button>
            </div>
        </form>
    );
};

export default AddClientForm;
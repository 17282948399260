// SyncPopup.js
import React from 'react';

const SyncPopup = ({ differences, onClose }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white p-6 rounded-lg max-w-4xl w-full max-h-[90vh] overflow-auto">
                <h2 className="text-2xl font-bold mb-4">Configuration Differences</h2>
                {differences ? (
                    <>
                        <RouteSection title="Missing in Cloudflare" routes={differences.missingInCloudflare} />
                        <RouteSection title="Missing in Supabase" routes={differences.missingInSupabase} />
                    </>
                ) : (
                    <p>No differences found. Configurations are in sync.</p>
                )}
                <button
                    onClick={onClose}
                    className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

const RouteSection = ({ title, routes }) => {
    if (!routes || routes.length === 0) return null;
    return (
        <div className="mb-4">
            <h4 className="font-medium mb-2">{title}</h4>
            <div className="bg-gray-100 p-4 rounded overflow-auto max-h-[30vh]">
                <ul className="list-disc pl-5">
                    {routes.map((route, index) => (
                        <li key={index} className="text-sm">{route}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SyncPopup;
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Switch } from '@headlessui/react';
import { LockClosedIcon, PencilIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { CONFIG_VARIABLES, DEFAULT_CONFIG } from '../constants/workerConfigConstants';


const WorkerConfigDetail = () => {
    const { id } = useParams();
    const [workerConfig, setWorkerConfig] = useState(null);
    const [editedConfig, setEditedConfig] = useState(null);
    const [associatedClients, setAssociatedClients] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchWorkerConfigDetails();
        fetchAssociatedClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    async function fetchWorkerConfigDetails() {
        const { data, error } = await supabase
            .from('worker_configurations')
            .select('*')
            .eq('id', id)
            .single();

        if (error) {
            console.log('Error fetching worker config:', error);
        } else {
            let config = data.config || {};
            let updatedConfig = { ...DEFAULT_CONFIG, ...config };

            // If config is empty or missing some keys, update it in the database
            if (Object.keys(config).length === 0 || Object.keys(updatedConfig).length !== Object.keys(config).length) {
                const { data: updatedData, error: updateError } = await supabase
                    .from('worker_configurations')
                    .update({ config: updatedConfig })
                    .eq('id', id)
                    .select()
                    .single();

                if (updateError) {
                    console.error('Error updating worker configuration:', updateError);
                } else {
                    setWorkerConfig(updatedData);
                    setEditedConfig(updatedData.config);
                }
            } else {
                setWorkerConfig(data);
                setEditedConfig(config);
            }
        }
    }

    async function fetchAssociatedClients() {
        const { data, error } = await supabase
            .from('clients')
            .select('id, name')
            .eq('worker_id', id);

        if (error) console.log('Error fetching associated clients:', error);
        else setAssociatedClients(data);
    }

    const formatConfigKey = (key) => {
        return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };

    const handleToggle = (key) => {
        if (isEditing) {
            const newConfig = { ...editedConfig, [key]: !editedConfig[key] };
            setEditedConfig(newConfig);
            setHasChanges(true);
        }
    };

    const handleSave = async () => {
        const { error } = await supabase
            .from('worker_configurations')
            .update({ config: editedConfig })
            .eq('id', id);

        if (error) {
            console.error('Error updating worker configuration:', error);
        } else {
            setWorkerConfig({ ...workerConfig, config: editedConfig });
            setIsEditing(false);
            setHasChanges(false);
        }
    };

    if (!workerConfig) return <div className="text-center p-4">Loading...</div>;

    return (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden max-w-2xl mx-auto mt-8">
            <div className="bg-darkBlue px-6 py-4 flex justify-between items-center">
                <h2 className="text-2xl font-bold text-white">{workerConfig.name}</h2>
                {!isEditing ? (
                    <button
                        onClick={() => setIsEditing(true)}
                        className="bg-lightBlue text-white p-2 rounded hover:bg-blue-600 transition duration-300"
                    >
                        <PencilIcon className="h-5 w-5" />
                    </button>
                ) : hasChanges ? (
                    <button
                        onClick={handleSave}
                        className="bg-green-500 text-white p-2 rounded hover:bg-green-600 transition duration-300"
                    >
                        <ArrowDownTrayIcon className="h-5 w-5" />
                    </button>
                ) : null}
            </div>
            <div className="p-6">
                <div className="mb-6">
                    <h3 className="text-lg font-semibold text-darkBlue mb-2">Configuration</h3>
                    <div className="space-y-4">
                        {Object.entries(editedConfig || {}).map(([key, value]) => (
                            <div key={key} className="flex items-center justify-between">
                                <span className="text-darkBlue">{CONFIG_VARIABLES[key]?.label || formatConfigKey(key)}</span>
                                <div className="flex items-center">
                                    <Switch
                                        checked={value}
                                        onChange={() => handleToggle(key)}
                                        disabled={!isEditing}
                                        className={`${value ? 'bg-darkBlue' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-darkBlue focus:ring-offset-2 ${isEditing ? '' : 'opacity-50 cursor-not-allowed'
                                            }`}
                                    >
                                        <span
                                            className={`${value ? 'translate-x-6' : 'translate-x-1'
                                                } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                                        />
                                    </Switch>
                                    {!isEditing && <LockClosedIcon className="h-4 w-4 text-gray-400 ml-2" />}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <h3 className="text-lg font-semibold text-darkBlue mb-2">Associated Clients</h3>
                    {associatedClients.length > 0 ? (
                        <ul className="space-y-2">
                            {associatedClients.map((client) => (
                                <li key={client.id} className="bg-lightGray p-3 rounded-md">
                                    <Link to={`/clients/${client.id}`} className="text-darkBlue hover:text-lightBlue transition duration-300">
                                        {client.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-lightBlue">No clients associated with this worker.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default WorkerConfigDetail;
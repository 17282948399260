import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserbackProvider } from '@userback/react';
import { supabase } from './supabaseClient';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Function to get user data from Supabase session
const getUserData = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  console.log('Supabase user data:', user);

  if (user) {
    return {
      id: user.id,
      info: {
        name: user.user_metadata.full_name,
        email: user.email
      }
    };
  }
  return null;
};

// Render function
const renderApp = async () => {
  const userData = await getUserData();
  console.log('User data for Userback:', userData);

  root.render(
    <React.StrictMode>
      <UserbackProvider
        token="A-0xVhNnhTfMJ6P0ePlM4raqpZa"
        options={{ user_data: userData }}
      >
        <App />
      </UserbackProvider>
    </React.StrictMode>
  );

  console.log('Userback provider initialized');
};

// Call the render function
renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import Layout from './components/Layout';
import Auth from './components/Auth';
import ClientsList from './components/ClientsList';
import ClientDetail from './components/ClientDetail';
import WorkerConfigsList from './components/WorkerConfigsList';
import WorkerConfigDetail from './components/WorkerConfigDetail';

function App() {
  const [session, setSession] = useState(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      console.log('Initial session:', session)
    })

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      console.log('Auth state changed:', session)
    })

    // Cleanup function
    return () => subscription.unsubscribe()
  }, [])

  console.log('Current session state:', session)

  return (
    <Router>
      {!session ? (
        <Auth />
      ) : (
        <Layout>
          <Routes>
            <Route path="/" element={<Navigate to="/clients" />} />
            <Route path="/clients" element={<ClientsList />} />
            <Route path="/clients/:id" element={<ClientDetail />} />
            <Route path="/workers" element={<WorkerConfigsList />} />
            <Route path="/workers/:id" element={<WorkerConfigDetail />} />
          </Routes>
        </Layout>
      )}
    </Router>
  );
}

export default App;
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { Link, useNavigate } from 'react-router-dom';
import AddClientForm from './AddClientForm';

const ClientsList = () => {
    const [clients, setClients] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [editingClient, setEditingClient] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchClients();
    }, []);

    async function fetchClients() {
        const { data, error } = await supabase
            .from('clients')
            .select(`
                *,
                worker_configurations (id, name)
            `);
        if (error) console.log('Error fetching clients:', error);
        else setClients(data);
    }

    const handleClientAdded = async (newClient) => {
        const { data, error } = await supabase
            .from('clients')
            .select(`
                *,
                worker_configurations (id, name)
            `)
            .eq('id', newClient.id)
            .single();

        if (error) {
            console.error('Error fetching updated client:', error);
        } else {
            setClients([...clients, data]);
        }
        setShowForm(false);
    };

    const handleClientUpdated = async (updatedClient) => {
        const { data, error } = await supabase
            .from('clients')
            .select(`
                *,
                worker_configurations (id, name)
            `)
            .eq('id', updatedClient.id)
            .single();

        if (error) {
            console.error('Error fetching updated client:', error);
        } else {
            setClients(clients.map(client => client.id === data.id ? data : client));
        }
        setEditingClient(null);
        setShowForm(false);
    };

    const handleDeleteClient = async (id) => {
        const { error } = await supabase
            .from('clients')
            .delete()
            .eq('id', id);

        if (error) {
            console.error('Error deleting client:', error);
        } else {
            setClients(clients.filter(client => client.id !== id));
        }
    };

    const handleEditClick = (client) => {
        setEditingClient(client);
        setShowForm(true);
    };

    const handleWorkerClick = (workerId) => {
        if (workerId) {
            navigate(`/workers/${workerId}`);
        }
    };

    return (
        <div className="space-y-6 max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold text-darkBlue">Clients</h2>
                {!showForm && (
                    <button
                        onClick={() => setShowForm(true)}
                        className="bg-darkBlue hover:bg-lightBlue text-white font-bold py-2 px-4 rounded transition duration-300"
                    >
                        Add Client
                    </button>
                )}
            </div>

            {showForm && (
                <AddClientForm
                    onClientAdded={handleClientAdded}
                    onClientUpdated={handleClientUpdated}
                    editingClient={editingClient}
                    onCancel={() => {
                        setShowForm(false);
                        setEditingClient(null);
                    }}
                />
            )}

            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-darkBlue">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                                Client
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                                Domain
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                                Worker
                            </th>
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-white uppercase tracking-wider">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {clients.map((client) => (
                            <tr key={client.id} className="hover:bg-gray-50 transition duration-150 ease-in-out">
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <Link to={`/clients/${client.id}`} className="text-sm font-medium text-darkBlue hover:text-lightBlue">
                                        {client.name}
                                    </Link>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <span className="text-sm text-gray-900">{client.domain}</span>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {client.worker_configurations && (
                                        <button
                                            onClick={() => handleWorkerClick(client.worker_configurations.id)}
                                            className="text-sm text-lightBlue hover:text-darkBlue"
                                        >
                                            {client.worker_configurations.name || 'No Worker'}
                                        </button>
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <button
                                        onClick={() => handleEditClick(client)}
                                        className="text-indigo-600 hover:text-indigo-900 mr-4"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDeleteClient(client.id)}
                                        className="text-red-600 hover:text-red-900"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ClientsList;
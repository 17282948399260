export const CONFIG_VARIABLES = {
    ENABLE_YOUTUBE_MODIFICATION: { label: "Enable YouTube Facade", value: false },
    ENABLE_VIMEO_MODIFICATION: { label: "Enable Vimeo Facade", value: false },
    ENABLE_IMAGE_MODIFICATION: { label: "Enable Image Resize/Transform", value: false },
    ENABLE_EXTERNAL_LINK_MODIFICATION: { label: "Enable External Link Modification", value: false },
    ENABLE_PRELOAD_HINTS: { label: "Enable Preload Hints", value: false },
    ENABLE_EXTERNAL_IMAGE_MODIFICATION: { label: "Enable External Image Modification", value: false },
    ENABLE_GEO_HANDLER: { label: "Enable Geo Handler", value: false },
    ENABLE_PLAUSIBLE_ANALYTICS: { label: "Enable Plausible Analytics", value: false },
    ENABLE_EDGE_CACHE: { label: "Enable Edge Cache", value: false },
    ENABLE_PLAUSIBLE_SYNC: { label: "Enable Plausible Sync", value: false },
    ENABLE_LOGGING: { label: "Enable Logging", value: false },
};

export const DEFAULT_CONFIG = Object.fromEntries(
    Object.entries(CONFIG_VARIABLES).map(([key, { value }]) => [key, value])
);
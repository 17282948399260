import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { Switch } from '@headlessui/react';
import { CONFIG_VARIABLES } from '../constants/workerConfigConstants';

const AddWorkerConfigForm = ({ onWorkerConfigAdded }) => {
    const [name, setName] = useState('');
    const [config, setConfig] = useState(CONFIG_VARIABLES);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const configValues = Object.entries(config).reduce((acc, [key, { value }]) => {
            acc[key] = value;
            return acc;
        }, {});

        const { data, error } = await supabase
            .from('worker_configurations')
            .insert({ name, config: configValues })
            .select()

        if (error) {
            console.error('Error adding worker configuration:', error);
        } else if (data && data.length > 0) {
            setName('');
            setConfig(CONFIG_VARIABLES);
            onWorkerConfigAdded(data[0]);
        } else {
            console.error('No data returned after insertion');
        }
    };

    const handleToggle = (key) => {
        setConfig(prevConfig => ({
            ...prevConfig,
            [key]: { ...prevConfig[key], value: !prevConfig[key].value }
        }));
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow-md">
            <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Worker Configuration Name</label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            
            {Object.entries(config).map(([key, { label, value }]) => (
                <Switch.Group key={key} as="div" className="flex items-center justify-between">
                    <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                        <span className="text-sm font-medium text-gray-900">{label}</span>
                    </Switch.Label>
                    <Switch
                        checked={value}
                        onChange={() => handleToggle(key)}
                        className={`${
                            value ? 'bg-indigo-600' : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                    >
                        <span
                            aria-hidden="true"
                            className={`${
                                value ? 'translate-x-5' : 'translate-x-0'
                            } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                        />
                    </Switch>
                </Switch.Group>
            ))}

            <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Add Worker Configuration
            </button>
        </form>
    );
};

export default AddWorkerConfigForm;
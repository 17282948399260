import React from 'react';
import { Link } from 'react-router-dom';

const Layout = ({ children }) => {
    return (
        <div className="min-h-screen" style={{ backgroundColor: '#edf2f4' }}>
            <nav className="bg-darkBlue shadow-sm">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex">
                            <Link to="/" className="flex-shrink-0 flex items-center text-white">
                                PressHive Admin
                            </Link>
                            <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                                <Link
                                    to="/clients"
                                    className="text-white inline-flex items-center px-1 pt-1 border-b-2 border-transparent hover:border-lightBlue text-sm font-medium"
                                >
                                    Clients
                                </Link>
                                <Link
                                    to="/workers"
                                    className="text-white inline-flex items-center px-1 pt-1 border-b-2 border-transparent hover:border-lightBlue text-sm font-medium"
                                >
                                    Workers
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                {children}
            </main>
        </div>
    );
};

export default Layout;

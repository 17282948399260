import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { Link } from 'react-router-dom';
import AddWorkerConfigForm from './AddWorkerConfigForm';
import SyncPopup from './SyncPopup';

const WorkerConfigsList = () => {
    const [workerConfigs, setWorkerConfigs] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [deployingWorkers, setDeployingWorkers] = useState({});
    const [deletingWorkers, setDeletingWorkers] = useState({});
    const [syncingWorkers, setSyncingWorkers] = useState({});
    const [syncDifferences, setSyncDifferences] = useState(null);
    const [statusDetailsPopup, setStatusDetailsPopup] = useState({ isOpen: false, details: '' });

    useEffect(() => {
        fetchWorkerConfigs();
        const channel = supabase.channel('worker_config_changes');
        channel
            .on('postgres_changes', { event: '*', schema: 'public', table: 'worker_configurations' }, handleWorkerConfigChange)
            .subscribe();

        return () => {
            channel.unsubscribe();
        };
    }, []);

    const handleWorkerConfigChange = (payload) => {
        const { new: newRecord } = payload;
        setWorkerConfigs(prev => prev.map(config =>
            config.id === newRecord.id ? { ...config, ...newRecord } : config
        ));
        if (newRecord.status !== 'deploying') {
            setDeployingWorkers(prev => ({ ...prev, [newRecord.id]: false }));
        }
    };

    async function fetchWorkerConfigs() {
        const { data, error } = await supabase
            .from('worker_configurations')
            .select('*')
            .order('created_at', { ascending: false });
        if (error) console.log('Error fetching worker configurations:', error);
        else setWorkerConfigs(data);
    }

    const handleWorkerConfigAdded = (newWorkerConfig) => {
        setWorkerConfigs([newWorkerConfig, ...workerConfigs]);
        setShowForm(false);
    };

    const handleDeleteWorkerConfig = async (id) => {
        try {
            setDeletingWorkers(prev => ({ ...prev, [id]: true }));

            // Fetch associated clients
            const { data: associatedClients, error: clientsError } = await supabase
                .from('clients')
                .select('id, name')
                .eq('worker_id', id);

            if (clientsError) throw clientsError;

            // Fetch the worker configuration
            const { data: workerConfig, error: workerError } = await supabase
                .from('worker_configurations')
                .select('name')
                .eq('id', id)
                .single();

            if (workerError) throw workerError;

            let confirmMessage = `Are you sure you want to delete the worker configuration "${workerConfig.name}"? This will also remove it from Cloudflare if it exists.`;

            if (associatedClients.length > 0) {
                const clientNames = associatedClients.map(client => client.name).join(', ');
                confirmMessage += `\n\nThe following clients are currently using this worker:\n${clientNames}\n\nThese clients will have their worker set to NULL.`;
            }

            if (window.confirm(confirmMessage)) {
                // First attempt to delete without confirmation
                const { data, error } = await supabase.functions.invoke('delete-worker', {
                    body: { workerId: id, confirmDbDeletion: false },
                });

                if (error) throw error;

                if (!data.success && data.proceedWithDbDeletion) {
                    const proceedWithDbDeletion = window.confirm(
                        `The worker "${workerConfig.name}" was not found in Cloudflare. It may have been deleted manually. Do you want to proceed with deleting it from the admin portal database?`
                    );

                    if (proceedWithDbDeletion) {
                        // Call the Edge Function again with confirmation
                        const { data: confirmData, error: confirmError } = await supabase.functions.invoke('delete-worker', {
                            body: { workerId: id, confirmDbDeletion: true },
                        });

                        if (confirmError) throw confirmError;
                        if (!confirmData.success) throw new Error(confirmData.error);

                        // Update the local state
                        setWorkerConfigs(workerConfigs.filter(config => config.id !== id));
                        alert('Worker configuration successfully deleted from the database. Associated clients have been updated.');
                    } else {
                        alert('Deletion cancelled. The worker configuration remains in the database.');
                    }
                } else if (!data.success) {
                    throw new Error(data.error);
                } else {
                    // Update the local state
                    setWorkerConfigs(workerConfigs.filter(config => config.id !== id));
                    alert('Worker configuration successfully deleted from Cloudflare and database. Associated clients have been updated.');
                }
            }
        } catch (error) {
            console.error('Error deleting worker configuration:', error);
            alert(`An error occurred during the deletion process:\n\n${error.message}\n\nPlease check the console for more details and contact support if needed.`);
        } finally {
            setDeletingWorkers(prev => ({ ...prev, [id]: false }));
        }
    };

    const handleDeployWorker = async (workerId) => {
        setDeployingWorkers(prev => ({ ...prev, [workerId]: true }));
        try {
            await supabase
                .from('worker_configurations')
                .update({
                    status: 'deploying',
                    status_details: 'Deployment started'
                })
                .eq('id', workerId);

            const { data, error } = await supabase.functions.invoke('deploy-worker', {
                body: { workerId },
            });

            if (error) throw error;

            console.log('Deployment initiated:', data);
        } catch (error) {
            console.error('Error initiating deployment:', error);
            await supabase
                .from('worker_configurations')
                .update({
                    status: 'failed',
                    status_details: `Deployment failed: ${error.message}`
                })
                .eq('id', workerId);
        } finally {
            setDeployingWorkers(prev => ({ ...prev, [workerId]: false }));
        }
    };

    const handleCheckSync = async (workerId) => {
        setSyncingWorkers(prev => ({ ...prev, [workerId]: true }));
        try {
            const { data, error } = await supabase.functions.invoke('check-worker-sync', {
                body: { workerId },
            });

            if (error) throw error;

            if (data.differences) {
                setSyncDifferences(data.differences);
            } else {
                alert('Worker configuration is in sync with Cloudflare.');
            }
        } catch (error) {
            console.error('Error checking worker sync:', error);
            alert(`An error occurred while checking worker sync: ${error.message}`);
        } finally {
            setSyncingWorkers(prev => ({ ...prev, [workerId]: false }));
        }
    };

    const getDeployButtonText = (status) => {
        switch (status) {
            case 'deploying': return 'Deploying...';
            case 'deployed': return 'Deploy';
            case 'failed': return 'Deploy';
            default: return 'Deploy';
        }
    };

    const isDeployButtonDisabled = (config) => {
        return config.status === 'deploying' || deployingWorkers[config.id] || deletingWorkers[config.id];
    };

    const isDeleteButtonDisabled = (config) => {
        return config.status === 'deploying' || deployingWorkers[config.id] || deletingWorkers[config.id];
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'deploying': return 'text-yellow-600';
            case 'deployed': return 'text-green-600';
            case 'failed': return 'text-red-600';
            default: return 'text-gray-600';
        }
    };

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold text-darkBlue">Worker Configurations</h2>
                <button
                    onClick={() => setShowForm(!showForm)}
                    className="bg-darkBlue hover:bg-lightBlue text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                    {showForm ? 'Cancel' : 'Add Worker Config'}
                </button>
            </div>

            {showForm && <AddWorkerConfigForm onWorkerConfigAdded={handleWorkerConfigAdded} />}

            <div className="bg-white shadow overflow-hidden sm:rounded-md">
                <ul className="divide-y divide-gray-200">
                    {workerConfigs.map((config) => (
                        <li key={config.id} className="flex justify-between items-center">
                            <Link to={`/workers/${config.id}`} className="block hover:bg-lightGray flex-grow">
                                <div className="px-4 py-4 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm font-medium text-darkBlue truncate">{config.name}</p>
                                        <div className="flex items-center">
                                            <p className={`text-xs ${getStatusColor(config.status)}`}>
                                                {config.status || 'Not deployed'}
                                            </p>
                                            {config.status === 'failed' && config.status_details && (
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent the Link from navigating
                                                        setStatusDetailsPopup({ isOpen: true, details: config.status_details });
                                                    }}
                                                    className="ml-2 text-gray-500 hover:text-gray-700"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div className="flex items-center">
                                <button
                                    onClick={() => handleCheckSync(config.id)}
                                    disabled={syncingWorkers[config.id]}
                                    className={`
                                        ${syncingWorkers[config.id]
                                            ? 'bg-gray-400 cursor-not-allowed'
                                            : 'bg-blue-500 hover:bg-blue-600'
                                        } text-white font-bold py-2 px-4 rounded mr-2 transition duration-300
                                    `}
                                >
                                    {syncingWorkers[config.id] ? 'Checking...' : 'Check Sync'}
                                </button>
                                <button
                                    onClick={() => handleDeployWorker(config.id)}
                                    disabled={isDeployButtonDisabled(config)}
                                    className={`
                                        ${isDeployButtonDisabled(config)
                                            ? 'bg-gray-400 cursor-not-allowed'
                                            : 'bg-green hover:bg-green-hover'
                                        } text-white font-bold py-2 px-4 rounded mr-2 transition duration-300 w-32
                                    `}
                                >
                                    {getDeployButtonText(config.status)}
                                </button>
                                <button
                                    onClick={() => handleDeleteWorkerConfig(config.id)}
                                    disabled={isDeleteButtonDisabled(config)}
                                    className={`
                                        ${isDeleteButtonDisabled(config)
                                            ? 'bg-gray-400 cursor-not-allowed'
                                            : 'bg-red hover:bg-red-hover'
                                        } text-white font-bold py-2 px-4 rounded mr-4 transition duration-300
                                    `}
                                >
                                    {deletingWorkers[config.id] ? 'Deleting...' : 'Delete'}
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {syncDifferences && (
                <SyncPopup
                    differences={syncDifferences}
                    onClose={() => setSyncDifferences(null)}
                />
            )}

            <StatusDetailsPopup
                isOpen={statusDetailsPopup.isOpen}
                details={statusDetailsPopup.details}
                onClose={() => setStatusDetailsPopup({ isOpen: false, details: '' })}
            />
        </div>
    );
};

const StatusDetailsPopup = ({ isOpen, details, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4" onClick={onClose}>
            <div className="relative bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] flex flex-col" onClick={e => e.stopPropagation()}>
                <div className="p-6 flex-shrink-0 border-b">
                    <h3 className="text-xl font-semibold text-gray-900">Deployment Error Details</h3>
                </div>
                <div className="p-6 overflow-y-auto flex-grow">
                    <pre className="text-sm text-gray-500 whitespace-pre-wrap break-words">
                        {details}
                    </pre>
                </div>
                <div className="p-6 flex-shrink-0 border-t">
                    <button
                        onClick={onClose}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WorkerConfigsList;
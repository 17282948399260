import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const ClientDetail = () => {
    const { id } = useParams();
    const [client, setClient] = useState(null);
    const [workerConfig, setWorkerConfig] = useState(null);
    const [routes, setRoutes] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchClientDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    async function fetchClientDetails() {
        const { data: clientData, error: clientError } = await supabase
            .from('clients')
            .select('*')
            .eq('id', id)
            .single();

        if (clientError) {
            console.log('Error fetching client:', clientError);
            return;
        }

        setClient(clientData);

        if (clientData.worker_id) {
            const { data: workerData, error: workerError } = await supabase
                .from('worker_configurations')
                .select('*')
                .eq('id', clientData.worker_id)
                .single();

            if (workerError) console.log('Error fetching worker config:', workerError);
            else setWorkerConfig(workerData);
        }

        if (clientData.routes_id) {
            const { data: routesData, error: routesError } = await supabase
                .from('client_routes')
                .select('*')
                .eq('id', clientData.routes_id)
                .single();

            if (routesError) console.log('Error fetching routes:', routesError);
            else setRoutes(routesData.routes);
        }
    }

    if (!client) return <div className="text-center p-4">Loading...</div>;

    return (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden max-w-2xl mx-auto mt-8">
            <div className="bg-darkBlue px-6 py-4">
                <h2 className="text-2xl font-bold text-white">{client.name}</h2>
                {client.domain && (
                    <p className="text-lightBlue mt-1">{client.domain}</p>
                )}
            </div>
            <div className="p-6">
                <div className="mb-6">
                    <h3 className="text-lg font-semibold text-darkBlue mb-2">Worker Configuration</h3>
                    <p className="text-lightBlue">
                        {workerConfig ? workerConfig.name : 'Not assigned'}
                    </p>
                </div>
                <div>
                    <h3 className="text-lg font-semibold text-darkBlue mb-2">Routes</h3>
                    {routes.length > 0 ? (
                        <ul className="space-y-2">
                            {routes.map((route, index) => (
                                <li key={index} className="bg-lightGray p-3 rounded-md">
                                    <p><span className="font-medium">Pattern:</span> {route.pattern}</p>
                                    <p><span className="font-medium">Zone ID:</span> {route.zone_id}</p>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-lightBlue">No routes assigned</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ClientDetail;
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY


export const supabase = createClient(supabaseUrl, supabaseKey);

export const signInWithGoogle = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        // options: {
        //     redirectTo: window.location.origin
        // }
    });
    if (error) console.error('Error during Google sign-in:', error);
};


// export const signInWithEmail = async (email, password) => {
//     console.log("Email sign-in initiated", email);
//     const { error } = await supabase.auth.signInWithPassword({ email, password });
//     return error;
// };

// export const signUpWithEmail = async (email, password) => {
//     console.log("Email sign-up initiated", email);
//     const { error } = await supabase.auth.signUp({ email, password });
//     return error;
// };